import React from "react";
import NoDataImg from "asserts/images/noData.svg";
import { ReportPage } from "./report";

let container =
  "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl m-auto p-6";
let title = "font-extrabold text-3xl";
let sub_title = "font-bold text-2xl text-center";

export const RecallPage = ({ data, firstData }) => {
  return (
    <section className="m-4 ">
      <div className={`${container}`}>
        <h2 className={`${title} text-center pb-20`}>Recalls / استدعاءات</h2>
        <div
          className="shadow-lg pt-20 pb-10 rounded-lg"
          style={{ border: "1px solid" }}
        >
          <div className="flex items-center justify-around flex-wrap">
            <div>
              {!firstData?.Model ? (
                <p className={`${sub_title}`}>No VIN number found</p>
              ) : (
                <>
                  <p className={`${sub_title}`}>Mitsubishi Motors </p>
                  <p className={`${sub_title}`}>سيارات ميتسوبيشي</p>
                  <div className="w-96 flex justify-center py-10 " id="car-img">
                    <img
                      src={firstData?.image_url}
                      alt="img"
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                  <p className={`${sub_title}`}>{firstData?.Model || ""}</p>
                </>
              )}
            </div>
            <div className="border border-red-500	bg-[#ffff] w-44 rounded-lg">
              <p className="text-xl p-3 text-center bg-[#DB3B44] text-white rounded-t-lg">
                Recalls 
              </p>
              <p
                className="text-3xl py-5 px-3 text-center"
                style={{ borderRadius: "0px 0px 7px 7px" }}
              >
                {data?.length || 0}
              </p>
            </div>
          </div>
          {firstData?.image_url && (
            <>
            <p className="text-xl w-full text-center pt-12">
              * Vehicle images are used for illustrative purposes only.
            </p>
            <p className="text-xl w-full text-center pt-12">صور السيارات المستخدمة هي من أجل التوضيح فقط</p>
            </>
          )}
        </div>
        {data?.length === 0 ? (
          <div className="flex flex-col gap-3 items-center w-full justify-center mt-20">
            <img src={NoDataImg} width={"15%"} alt="No Data" />
            <p className="text-2xl text-slate-400">No Recall Found!!!</p>
            <p className="text-2xl text-slate-400">لا يوجد أي استدعاء</p>
          </div>
        ) : (
          <ReportPage data={data} firstData={firstData} />
        )}
      </div>
    </section>
  );
};
