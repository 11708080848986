import React, { useState } from "react";
import FusoLogo from "asserts/images/fuso.png";
import GacLogo from "asserts/images/gac.png";
import MitsuLogo from "asserts/images/mitsu.png";
import { Input } from "components/fields";
import { CiSearch } from "react-icons/ci";
import { RecallPage } from "./recall";
import { MainPage } from "./main";
import { fetchVinNumber } from "api/vinNum";
import { Loader } from "components/loader";
import ErrorPage from "asserts/images/error.jpg";

let container =
  "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl m-auto p-6";

export const RecallMainPage = () => {
  const [recallPage, setRecallPage] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState(false);
  const [firstData, setFirstData] = useState("");
  const [vinNum, setVinNum] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [vinMsg, setVinMsg] = useState();
  const [phoneMsg, setPhoneMsg] = useState();
  const [loader, setLoader] = useState(false);

  const fetchData = () => {
    let vinLen = vinNum.length >= 17;
    let numLen = phoneNum.length >= 8;
    if (!vinLen || !numLen) {
      if (!vinLen) {
        setVinMsg("Please enter vaild VIN number");
      } else {
        setVinMsg(null);
      }

      if (!numLen) {
        setPhoneMsg("Please enter vaild mobile number");
      } else {
        setPhoneMsg(null);
      }
    } else {
      setVinMsg(null);
      setPhoneMsg(null);
      setLoader(true);
      fetchVinNumber({ search: vinNum, phone: phoneNum })
        .then(({ data }) => {
          setLoader(false);
          setRecallPage(true);
          setFirstData(data?.[0]);
          let r = data.filter(
            (arr) => arr?.recall_heading?.ID && arr?.recall_heading
          );
          setData(r.map((arr) => arr?.recall_heading));
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          setErrorMsg(err);
        });
    }
  };

  return (
    <main>
      <section className="bg-[#DB3B44]">
        <div
          className={`${container} flex justify-between gap-2 items-center flex-warp bg-[#DB3B44]`}
        >
          <div className="w-28 bg-[#DB3B44]">
            <a href="/">
              <img src={MitsuLogo} className="w-full bg-[#DB3B44]" alt="Logo" />
            </a>
          </div>
          <div className="w-60 bg-[#DB3B44]">
            <a href="/">
              <img src={GacLogo} className="w-full bg-[#DB3B44]" alt="Logo" />
            </a>
          </div>
          <div className="w-36 bg-[#DB3B44]">
            <a href="/">
              <img src={FusoLogo} className="w-full bg-[#DB3B44]" alt="Logo" />
            </a>
          </div>
        </div>
      </section>
      <section className="pt-20 pb-0 m-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            fetchData();
          }}
        >
          <div
            className={`${container} rounded-md`}
            style={{ border: "2px solid" }}
          >
            <Input
              icon={<CiSearch style={{ fontSize: "1.5rem" }} />}
              label={"Search VIN Number / أدخل رقم قاعدة المركبة/ الشاصي"}
              value={vinNum}
              maxLength={17}
              onChange={(e) => setVinNum(e.target.value)}
            />
            <span style={{ color: "red" }}>{vinMsg && vinMsg}</span>
            <Input
              label={"Mobile Number / رقم النقال"}
              value={phoneNum}
              onChange={(e) => setPhoneNum(e.target.value)}
              maxLength={8}
            />
            <span style={{ color: "red" }}>{phoneMsg && phoneMsg}</span>

            <div
              className="w-full flex justify-center text-center py-2"
              style={{}}
            >
              <button className="w-[13rem] bg-[#DB3B44] py-4 text-white text-xl rounded-md">
                Submit / ابحث
              </button>
            </div>
          </div>
        </form>
      </section>
      {loader ? (
        <Loader />
      ) : (
        <>
          {errorMsg ? (
            <div className="flex justify-center flex-col items-center font-semibold">
              <h1 className="text-3xl ">Something Not Right...!!!!</h1>
              <img src={ErrorPage} width={"40%"} alt="Error Page" />
            </div>
          ) : (
            <>
              {recallPage ? (
                <RecallPage data={data} firstData={firstData} />
              ) : (
                <MainPage />
              )}
            </>
          )}
        </>
      )}
    </main>
  );
};
