import React from "react";

export const Input = ({
  label,
  value,
  onChange,
  type,
  name,
  icon,
  ...rest
}) => {
  return (
    <>
      <div
        className="pb-6"
        style={{ backgroundColor: "rgb(211 211 211 / 0%)" }}
      >
        <div
          className="relative mt-2 rounded-md shadow-sm"
          style={{ backgroundColor: "rgb(211 211 211 / 0%)" }}
        >
          <input
            name={name}
            id={name}
            className="block text-white h-[3.4rem] w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
            placeholder={label}
            value={value}
            onChange={onChange}
            type={type || "text"}
            required
            {...rest}
          />
          {icon && (
            <div
              class="absolute inset-y-0 end-0 flex items-center pointer-events-none"
              style={{ height: 0, top: 26, right: 18 }}
            >
              {icon}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
