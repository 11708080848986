import React from "react";
import CarImg from "asserts/images/car.png";
import CardImg from "asserts/images/card.jpeg";

let container =
  "max-w-screen-sm max-w-screen-md max-w-screen-lg max-w-screen-xl max-w-screen-2xl m-auto p-6";
let title = "font-extrabold text-4xl";
let title2 = "font-extrabold text-2xl";
let title3 = "font-extrabold text-1xl";

export const MainPage = () => {
  return (
    <section className="pb-10 pt-0 m-4">
      <div className={`${container}`}>
        <div>
          <h1 className={`${title} pb-4 text-center`}>
            Service Recall           </h1>
            <h1 className={`${title} pb-4 text-center`}>خدمة الاستدعاء</h1>
          <p className="text-xl text-center">
            Check if your vehicle needs any enhancement as a part of the
            Manufacturer’s recall!
          </p>
          <p className="text-xl text-center" > .الرجاء التأكد إن كانت مركبتك تحتاج إلى أي تحسينات كجزء من استدعاء المصنع </p>
        </div>
        <h4 className={`${title3} pt-8 `}>
          Vehicle Identification Number VIN OR Chassis number{" "}
        </h4>
        <h4 className={`${title3} pb-8`}> رقم قاعدة المركبة أو رقم الشاصي</h4>
        <p className="text-xl">
          Every vehicle has a unique VIN / Chassis number. Enter your VIN /
          CHASSIS number to learn if your vehicle needs any enhancement as a
          part of the Manufacturer’s recall. 
        </p>
        <p className="text-xl"> كل مركبة تتميز برقم قاعدة المركبة/ الشاصي الخاص بها. أدخل رقم قاعدة المركبة أو رقم الشاصي الخاص بمركبتك وسيظهر لك إن كانت مركبتك تحتاج لأي تحسينات كجزء من استدعاء المصنع</p>
        <div className="py-10">
          <h3 className={`${title2} pb-4`}>
            Where's my VIN / CHASSIS number ?
          </h3>
          <h3 className={`${title2} pb-4`}>أين أجد رقم القاعدة/ الشاصي الخاص بمركبتي؟</h3>
          <p className="text-xl">
            Look at the lower corner of your windshield on the driver’s side or
            the driver-side doorpost for your 17-character Vehicle
            Identification Number ( Chassis No ), as shown in the image below.
          </p>
          <p className="text-xl">بإمكانك إيجاد رقم القاعدة/ الشاصي عبر النظر إلى الزاوية اليسرى السفلية من زجاج السيارة الأمامي من جهة السائق، أو على جانب باب السائق ستجد رقم القاعدة/ الشاصي المكون من 17 رقماً، كما هو موضح بالصورة أدناه.</p>
          <p className={`${title3} pt-4`}>
            Your VIN / CHASSIS is also on your Motor Vehicle Registration card (
            Mulkiya ).
          </p>
          <p className={`${title3} pt-4`}> (الملكية)
            على رخصة مركبتك
             بإمكانك أيضاً إيجاد رقم القاعدة/ الشاصي</p>
        </div>
        <div className="py-8">
          <h2 className={`${title2} pb-4`}>
            What exactly does this VIN / CHASSIS search tool check?
          </h2>
          <h2 className={`${title2} pb-4`}>ما هو الغرض من أداة البحث عن قاعدة المركبة/ الشاصي؟</h2>
          <p className="text-xl	">
            This tool checks if a vehicle has unrepaired safety recalls in the
            past 10 years
          </p>
          <p className="text-xl	pt-4">أداة البحث هذه هي وسيلة لمعرفة إن كانت مركبتك تحتاج إلى تصليحات فنية متعلقة بسلامة المركبة، وتشمل الاستدعاءات التي تمت خلال السنوات العشر الماضية</p>
        </div>
        <div className="py-8">
          <h2 className={`${title2} pb-4`}>
            Which vehicles will this VIN / CHASSIS search tool not show ?
          </h2>
          <h2 className={`${title2} pb-4`}>ما هي المركبات التي لن تظهر في أداة البحث عن قاعدة المركبة/ الشاصي؟</h2>
          <p className="text-xl	pt-4">
            This tool will not show vehicles that don’t have safety recalls or
            vehicles with a repaired safety recall.
          </p>
          <p className="text-xl	pt-4">هذه الأداة لن تظهر أي مركبات لم يتم استدعاؤها لأي خلل فني ولا المركبات التي تم تصليحها في الاستدعاءات السابقة</p>
          <p className="text-xl	pt-4">
            A vehicle with a repaired safety recall will display "0 Unrepaired
            recalls associated with this VIN/CHASSIS" if there are no
            outstanding recalls.
          </p>
          <p className="text-xl	pt-4">المركبات التي تم تصليح عيوبها الفنية خلال الاستدعاءات السابقة، سيظهر في الخانة الرقم "صفر" استدعاءات لم يتم معالجتها مصحوباً برقم الشاصي إذا لم يكن هناك أي استدعاءات قيد الانتظار</p>
          <p className="text-xl	pt-4">
            This tool will not show any recall information on international
            vehicles.
          </p>
          <p className="text-xl	pt-4">أداة البحث هذه لن تظهر تفاصيل استدعاءات لمركبات مسجلة دولياً</p>
          <p className="text-xl	pt-4">
            There may be a delay in updating the Chassis with recently announced
            safety recalls for which not all VIN / CHASSIS numbers have been
            identified. VIN / CHASSIS numbers are added continuously, so please
            check them regularly.
          </p>
          <p className="text-xl	pt-4">قد يحصل تأخير في تحديث قائمة أرقام الشاصي عند الإعلان عن استدعاءات لتصليح أعطال فنية ولن تظهر جميع أرقام القاعدة/ الشاصي للمركبات في نفس اللحظة، وسيتم تحديث القائمة لحين اكتمالها. لذا، يرجى مراجعة القائمة باستمرار</p>
        </div>
        <div className="py-14 flex justify-between flex-wrap gap-10">
          <div>
            <img src={CarImg} alt="img" style={{ borderRadius: "20px" }} />
          </div>
          <div>
            <img
              src={CardImg}
              style={{ borderRadius: "20px" }}
              loading="lazy"
              alt="img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
