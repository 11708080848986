import React, { useEffect, useState } from "react";
import { BsCaretDownSquare, BsCaretUpSquare } from "react-icons/bs";
import { MdLocalPhone } from "react-icons/md";

// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// let data = [{}];

export const ReportPage = ({ firstData, data }) => {
  const [reportId, setReportId] = useState([]);

  useEffect(() => {
    if (reportId.length === 0) {
      setReportId([
        ...Array.from({ length: data.length || 10 }, (_, index) => index),
      ]);
    }
  }, []);

  const reportOnClick = (value) => {
    if (reportId.includes(value)) {
      var index = reportId.indexOf(value);

      if (index !== -1) {
        reportId.splice(index, 1);
      }

      setReportId([...reportId]);
    } else {
      let data = reportId;
      data.push(value);
      setReportId([...data]);
    }
  };

  return (
    <section
      style={{ backgroundColor: "transparent", color: "black" }}
      className="pt-20"
    >
      <div>
        <p className="font-normal text-2xl py-4">RECALLS </p>
        <hr class="h-px bg-gray-200 border-1 border-white-700" />
        <h1 className="font-normal text-3xl pt-8">{data?.length} Recalls</h1>
        <p className="text-xl font-light	uppercase pt-4">
          {firstData?.modelName}
        </p>
      </div>
      {/* <h1 className="font-normal text-4xl pt-8"></h1> */}
      <div
        class="hs-accordion-group p-10"
        style={{ color: "black" }}
        id="desc-view"
      >
        {data.map((arr, i) => {
          return (
            <div
              class="hs-accordion active shadow-lg p-10"
              id="hs-basic-with-title-and-arrow-stretched-heading-one"
              style={{
                border: "1px solid",
                borderRadius: 12,
                marginBottom: 20,
              }}
            >
              <button
                class="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start text-gray-800  rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400"
                aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one"
                onClick={() => reportOnClick(i)}
              >
                <span
                  className="text-xl"
                  style={{
                    fontWeight: 800,
                  }}
                >
                  {arr?.RecallDescription}
                </span>
                {reportId === i ? <BsCaretDownSquare /> : <BsCaretUpSquare />}
              </button>
              <p className="text-xl py-4">
                {arr?.Date}{" "}
                <span
                  style={{
                    fontWeight: 800,
                    paddingLeft: "10px",
                  }}
                >
                  TSL Number:
                </span>{" "}
                {arr?.SSL_No}
              </p>
              {/* <p className="text-xl pb-6 pt-2">
                <span className="font-bold">Subject:</span> Driver seat air bag
                Inflator on Pajero, Galant
              </p> */}
              {reportId.includes(i) && (
                <div className="recall mt-10">
                  <div className="flex gap-12 flex-wrap">
                    <div
                      className={`border border-red-500 w-${
                        arr?.CampaignCode.length > 8 ? 50 : 44
                      } rounded-lg`}
                    >
                      <p className="text-xl p-3 text-center bg-[#DB3B44] text-white rounded-t-lg">
                        Campaign Code
                      </p>
                      <p
                        className="text-3xl py-5 px-3 text-center"
                        id="report-value"
                        style={{ borderRadius: "0px 0px 8px 8px" }}
                      >
                        {arr?.CampaignCode}
                      </p>
                    </div>
                    <div
                      className={`border border-red-500  w-${
                        arr?.Franchise.length > 8 ? 50 : 44
                      } rounded-lg`}
                    >
                      <p className="text-xl p-3 text-center bg-[#DB3B44] text-white rounded-t-lg">
                        Franchise
                      </p>
                      <p
                        className="text-3xl py-5 px-3 text-center"
                        id="report-value"
                        style={{ borderRadius: "0px 0px 8px 8px" }}
                      >
                        {arr?.Franchise}
                      </p>
                    </div>
                    <div className="border border-red-500 rounded-lg">
                      <p className="text-xl p-3 text-center bg-[#DB3B44] text-white rounded-t-lg">
                        Vehicle
                      </p>
                      <p
                        className="text-3xl py-5 px-3 text-center"
                        id="report-value"
                        style={{ borderRadius: "0px 0px 8px 8px" }}
                      >
                        {arr?.Vehicle}
                      </p>
                    </div>
                  </div>
                  {/* <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                      header: {
                        disableHeader: false,
                        disableFileName: false,
                        retainURLParams: false,
                      },
                      pdfVerticalScrollByDefault: true,
                    }}
                  /> */}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <p className="font-normal text-3xl py-10 text-center ">
        For inquiries regarding your recall, please reach out to us at
      </p>
      <p className="font-normal text-3xl py-10 text-center ">لمزيد من التفاصيل والاستعلام عن استدعاء مركبتك، يرجى التواصل معنا على:</p>
      <div className="flex w-full items-center justify-center gap-10 flex-wrap">
        <a
          className="text-center w-[18rem] bg-[#DB3B44] py-4 px-2 text-white text-xl rounded-md"
          href="https://bookings.mitsubishi-motors.com.om/en/service-booking"
          target="_blank"
          rel="noreferrer"
        >
          Online Service Booking / خدمة الحجز أون لاين
        </a>
        <a
          className=" flex items-center justify-center gap-2 w-[13rem] bg-[#DB3B44] py-4 text-white text-xl rounded-md"
          href="tel:24500500"
        >
          <MdLocalPhone
            style={{ backgroundColor: "transparent", color: "black" }}
            fontSize={"24px"}
          />
          24500500
        </a>
      </div>
    </section>
  );
};
